<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";

export default {
  name: "OrderStatus",

  metaInfo: { title: "Verificá el estado de la orden!" },

  extends: View,

  mixins: [LoadSections(["layout-header-menu", "public-order-status"])],
};
</script>
